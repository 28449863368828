import React from 'react';
import { byMonth } from '../info/projects'

let temp = []
let aux = []

const Block = ({temp}) => {
  return (
        temp.map((item, index) => {
            return (
            <div key={"PM"+index}>
                <div className="col-md-4">
                  <h4>{item.name}</h4>
                  <p className="text-muted">{item.desc}</p>
               </div>
              </div>
            )
        })
  )
}

const ProjectsByMonth = () => {
    let num = 0
    let max = byMonth.length - 1
    return (
      byMonth.map((item, index) => {
          num = num + 1
          if (num === 3 || index === max ) {
            aux = aux.concat(item)
            temp = aux
            aux = []
            num = 0
            return (
              <div key={"SK"+index}>
                <div className="row text-center">
                  <Block temp={temp}/>
                </div>
                <hr />
              </div>
              )
          } else {
            aux = aux.concat(item)
            return null
          }
      })
    )
}

export default ProjectsByMonth
