import React, {useEffect} from 'react';
import { current, byYear, byMonth, byDay, webDev } from "../info/projects";
import GoTop from "../components/GoTop";

const CurrentProject = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }, []);
  return current.map((item, index) => {
    return (
      <div key={"CP"+index}>
        <div className="row text-center">
          <h6>
            <strong>{item.name}</strong>
            <br />
            {item.desc}
            <br />
            {item.time}
          </h6>
        </div>
      </div>
    );
  });
};

const ProjectsByTime = () => {
  return (
    <section id="projects">
      <div className="only text-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">Projects by Time</h2>
              <h3 className="section-subheading text-muted">

              </h3>
            </div>
          </div>

          <h4>
            <span className="glyphicon glyphicon-calendar"></span>&ensp;
            <i>Now</i>
          </h4>

          <CurrentProject />

          <hr />
          <h4>
            <span className="glyphicon glyphicon-calendar"></span>&ensp;
            <i>Years</i>
          </h4>

          {byYear.map((item, index) => {
            return (
              <div key={"Y" + index}>
                <h6>
                  <strong>{item.name}</strong>
                  <br />
                  {item.desc}
                  <br />
                  <i> {item.time} </i>
                </h6>
              </div>
            );
          })}

          <h4>
            <i>*****</i>
          </h4>

          {webDev.map((item, index) => {
            return (
              <div key={"W" + index}>
                <h6>
                  <strong>{item.name}</strong>
                  <br />
                  {item.desc}
                  <br />
                  <i> {item.time} </i>
                </h6>
              </div>
            );
          })}

          <hr />
          <h4>
            <span className="glyphicon glyphicon-calendar"></span>&ensp;
            <i>Months</i>
          </h4>

          {byMonth.map((item, index) => {
            return (
              <div key={"M" + index}>
                <h6>
                  <strong>{item.name}</strong>
                  <br />
                  {item.desc}
                  <br />
                  <i> {item.time} </i>
                </h6>
              </div>
            );
          })}

          <hr />
          <h4>
            <span className="glyphicon glyphicon-calendar"></span>&ensp;
            <i>Days</i>
          </h4>

          {byDay.map((item, index) => {
            if (item.remote === "N") {
              return (
                <div key={"D" + index}>
                  <h6>
                    <strong>{item.name}</strong>
                    <br />
                    {item.desc}
                    <br />
                    <i> {item.time} </i>
                  </h6>
                </div>
              );
            } else {
              return (
                <div key={"D" + index}>
                  <h6>
                    <strong>{item.name}</strong>&nbsp;
                    <span className="glyphicon glyphicon-exclamation-sign"></span>
                    <br />
                    {item.desc}
                    <br />
                    <i> {item.time} </i>
                  </h6>
                </div>
              );
            }
          })}

          <h6>
            <strong></strong>
            <br />
            <span className="glyphicon glyphicon-exclamation-sign"></span>{" "}
            Remote Work
          </h6>
        </div>
      </div>
      <GoTop />
    </section>
  );
};

export default ProjectsByTime;
