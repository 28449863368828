import React, {useEffect} from 'react';
import Hero from "../components/Hero";
import Skills from '../components/LandingSkills';
import Projects from '../components/LandingProjects';
import Sites from '../components/LandingSites';
import Games from '../components/LandingGames';
import Intro from '../components/LandingIntro';
import GoTop from "../components/GoTop";

const Landing = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }, []);
  return (
    <div>
      <Hero />
      <Intro />
      <Skills/>
      <Projects />
      <Sites />
      <Games />
      <GoTop />
    </div>
  )
}

export default Landing;
