import React, {useEffect} from 'react';
import SkillsIntro from "../components/skills_sap";
import GoTop from "../components/GoTop";

const info = [
  "Experience since 1993 in consulting, implementation, training, support and presentation of the SAP ERP system",
  "SAP Standard, SAP/IS-OIL and SAP/RETAIL implementations",
  "SAP ERP",
  "SAP Netweaver",
  "SAP HANA",
  "SAP HANA XS",
  "SAP S/4 HANA",
  "SAP FIORI",
  "SAP HANA CLOUD PLATFORM",
  "BUILDING APPLICATIONS WITH SAP CLOUD APPLICATION PROGRAMMING MODEL",
  "BUILDING APPLICATIONS WITH ABAP RESTful APPLICATION PROGRAMMING MODEL",
  "BUILD BOTS WITH SAP INTELLIGENT ROBOTIC PROCESS AUTOMATION",
  "BUILD CHATBOTS WITH SAP Conversational AI",
  "SAPUI5 (SAP User Interface for HTML5)",
  "Building Portal Sites on SAP Cloud Platform",
  "Building Applications on SAP BTP with Microsoft Services",
  "SAP SPARTACUS (Angular-based JavaScript storefront for SAP Commerce Cloud)",
  "SAP MDG (Master Data Governance)",
  "Object-oriented concepts (OOP)",
  "Remote Function Call (RFC)",
  "XSLT",
  "EDI",
  "IDoc",
  "BAPI",
  "BADI",
  "ALV",
  "Sapscript",
  "Smartform",
  "Adobeform",
  "Workflow",
  "Web Dynpros",
  "Web Services",
  "Data Migration",
];

// const Slider = () => {
//   return (
//     <div>
//       <Carousel
//         showThumbs={false}
//         showStatus={false}
//         // showArrows={false}
//         showIndicators={false}
//         infiniteLoop
//         autoPlay
//         emulateTouch
//         // width="100%"
//         // dynamicHeight={true}
//       >
//         {items.map((item, index) => {
//           return (
//             <div
//               key={index}
//               style={{ padding: 50, height: 150, fontSize: "40px" }}
//             >
//               {item.name}
//             </div>
//           );
//         })}
//       </Carousel>
//       <br />
//     </div>
//   );
// };

// const SliderX = () => {
//   return (
//     <div>
//       <Carousel
//         showThumbs={false}
//         showStatus={false}
//         showArrows={false}
//         showIndicators={false}
//         infiniteLoop
//         autoPlay
//         emulateTouch
//         // width="100%"
//         // dynamicHeight={true}
//       >
//         {items.map((item, index) => {
//           return (
//             <div
//               key={index}
//               style={{ padding: 1, height: 80, fontSize: "20px" }}
//             >
//               {item.name}
//             </div>
//           );
//         })}
//       </Carousel>
//       <br />
//     </div>
//   );
// };

const AllSapSkills = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }, []);
  return (
    <>
      <div>
        <h3 className="section-heading text-muted">All Sap Skills</h3>
      </div>
      <br />
      <div className="col-lg-12">
        {info.map((value, index) => {
          return (
            <h5 key={index}>
              <i className="fa fa-check-square-o">&nbsp;</i>
              {value}
            </h5>
          );
        })}
      </div>
    </>
  );
};

const SapSkills = () => {
  return (
    <section id="skills">
      <div className="only">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">SAP Skills</h2>
              <h3 className="section-subheading text-muted">Our SAP Skills</h3>
            </div>
          </div>
          <SkillsIntro />
          <br />
          <AllSapSkills />
          <GoTop />
        </div>
      </div>
    </section>
  );
};

export default SapSkills;
