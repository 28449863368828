import React from "react";
import { Link } from "react-router-dom";

const AboutIntro = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading">About</h2>
          <h3 className="section-subheading text-muted">Career and Profile</h3>
        </div>
      </div>
      <h4 className="section-heading">Career</h4>
      <strong>
        <p className="about-item">
          I started my career in 1982 as a freelancer, programming on ZX
          Spectrum and Amstrad PCW in BASIC language.
        </p>
        <p className="about-item">
          In 1987 I joined PHILIPS Portugal as Software Support with duties in
          the Department of telecommunications and Data Systems.
        </p>
        <p className="about-item">
          In 1990 I was transferred to EDISOFT, with the functions of Systems
          Analyst and Programmer where I participated in the development of an
          integrated Hotel Management System in the COBOL language.
        </p>
        <p className="about-item">
          Later in 1993 I was transferred to ORIGIN Portugal, where I had the
          opportunity to have training in the SAP system in Madrid and
          Barcelona.
        </p>
        <p className="about-item">
          I started to have the role of Consultant and I was allocated to the
          Unilever group, first at FIMA and then at IGLO, where I collaborated
          in the installation of SD, MM, FI, PM and PP modules as an ABAP
          programmer.
        </p>
        <p className="about-item">
          In 1996 I was invited to be part of a team that started ROFF
          Consulting. I was, therefore, a founding partner and continued my work
          as SAP Consultant.
        </p>
        <p className="about-item">
          Then, I founded my own company in 1999, RESOLUTION - Consultores
          Informáticos, and a partnership with ROFF was started, that continued
          for more than 20 years.
        </p>
        <p className="about-item">
          In September 2002, I temporarily interrupted my career as a consultant
          and dedicated myself to explore Web Development (HTML, CSS,
          JAVASCRIPT)
        </p>
        <p className="about-item">
          In Setember 2003, I returned to SAP Consulting without interruptions
          until 2015.
        </p>
        <p className="about-item">
          In the begin of 2015, I temporarily interrupted my functions as SAP
          consultant again and dedicated to improve my know-how with Web
          Development, taking advantage of the knowledge acquired with SAPUI5
          (SAP user interface for HTML 5) and continuing with the training
          started in 2003 and successfully developing some projects.
        </p>
        <p className="about-item">
          At the end of 2016, I returned to SAP Consulting until now.
        </p>
        <p className="about-item">
          On October 1st 2021, ROFF became INETUM... and the partnership
          continues.
        </p>
        <p className="about-item">
          Now I'm again dedicated mainly to SAP Projects. And Web Development
          too, developing some sites and upgrade my knowledge in this area.
        </p>
      </strong>
      <br />
      <h4 className="section-heading">PROFILE</h4>
      <h5>
        <strong>
          Specialist Consultant / Software Architect / Team Leader
        </strong>
      </h5>
      <br />
      <p className="about-item">
        <strong>
          I am completely receptive to new technologies. I have an unusual
          ability to use and adapt unknown features.
          <br />I have an incredible facility to find errors or anomalies in
          code and quickly resolve them. I am easy to join teams.
          <br />I have an extreme dedication to everything I do. I prefer
          self-learning and I am in constant training.
          <br />I can work full time on weekdays and extra hours in the evenings
          or over the weekend whenever necessary.
          <br />I can work remotely without any problems.
          <br />I be able to sit in front of a computer for extended periods of
          time. In fact, I do that every day.
          <br />I have no family restrictions and, as such, I have all the time
          I need.
          <br />
          <br />I consider myself a Software Architect{" "}
          <span className="glyphicon glyphicon-exclamation-sign"></span> and I
          have the necessary skills:
        </strong>
      </p>
      <div className="about-item">
        <strong>
          <ul style={{ listStyleType: "none", paddingLeft: 0, paddingTop: 0 }}>
            <li>
              <i className="fa fa-check-square-o"></i> Problem-solving
            </li>
            <li>
              <i className="fa fa-check-square-o"></i> Leadership
            </li>
            <li>
              <i className="fa fa-check-square-o"></i> Full dedication
            </li>
            <li>
              <i className="fa fa-check-square-o"></i> Organization
            </li>
            <li>
              <i className="fa fa-check-square-o"></i> Communication
            </li>
            <li>
              <i className="fa fa-check-square-o"></i> No time restrictions
            </li>
            <li>
              <i className="fa fa-check-square-o"></i> Attention to detail
            </li>
            <li>
              <i className="fa fa-check-square-o"></i> Creativity Cross
            </li>
            <li>
              <i className="fa fa-check-square-o"></i> knowledge in different
              technologies and languages
            </li>
            <li>
              <i className="fa fa-check-square-o"></i> +39 years of experience
              in computer science and programming
            </li>
          </ul>
        </strong>
        <br />
        <p className="about-item">
          <span className="glyphicon glyphicon-exclamation-sign"></span>{" "}
          <em>
            "A software architect is an expert-level software developer who
            communicates with businesses and clients to design and execute
            solutions with a team of software engineers. A software architect
            makes executive software design decisions. They often act as a
            designer, developer and communicator."
          </em>
        </p>
      </div>
      <br />
      <br />
      <div className="col-md-6">
        <button type="button" className="rci-button">
          <Link to="/Timeline">Show Timeline</Link>
        </button>
      </div>
    </div>
  );
};

export default AboutIntro;
