export const current = [
  { "name": 'INETUM ES - GOBIERNO BALEARES', "desc": '(Gobierno de las Islas Baleares / Government of the Balearic Islands', "time": "since 2024.08.01" }
]

export const highlights = [
  { "name": 'TAP Air Portugal', "desc": '(Airline company)' },
  { "name": 'GALPenergia', "desc": '(Energy Company)' },
  { "name": 'SOLVAY Portugal', "desc": '(Chemical Company)' },
  { "name": 'SONAE SIERRA', "desc": '(International real estate services and property investment company)'},
  { "name": 'TAKASAGO', "desc": '(International producer of flavours and fragrances)'},
  { "name": 'ASCENDUM', "desc": '(Construction and Industrial Equipment)' },
  { "name": 'UNILEVER (FIMA / IgloOlá)', "desc": '(Consumer Goods Company)' },
  { "name": 'JANSSEN-CILAG Portugal', "desc": '(Pharmaceutical Company)' }
]

export const important = [
  { "name": 'OPTIMUS', "desc": '(Mobile Phone Operator)' },
  { "name": 'ZON', "desc": '(Cable Operator)' },
  { "name": 'NOS', "desc": '(Cable Operator/Mobile Phone Operator)' }
]

export const webDev = [
  { "name": '@RESOLUTION', "desc": '(Web Development)', "time": ' 3 years, 1 month' }
]

export const byYear = [
    { "name": 'TAP Air Portugal', "desc": '(Airline company)', "time": ' 5 years, ...' },
    { "name": 'UNILEVER Group: FIMA / IgloOlá', "desc": '(Consumer Goods Company)', "time": ' 4 years, 3 months' },
    { "name": 'SOLVAY Portugal', "desc": '(Chemical Company)', "time": ' 2 years, 8 months' },
    { "name": 'GALPenergia', "desc": '(Energy Company)', "time": ' 2 years, 5 months' },
    { "name": 'SONAE SIERRA', "desc": '(International real estate services and property investment company)', "time": ' 2 years, 2 months' },
    { "name": 'ASCENDUM', "desc": '(Construction and Industrial Equipment)', "time": ' 1 year, 7 months' },
    { "name": 'TAKASAGO', "desc": '(International producer of flavours and fragrances)', "time": " 1 year, 4 months"},
    { "name": 'BE Artis/OPTIMUS/ZON/NOS', "desc": '(Cable Operator/Mobile Phone Operator, etc.)', "time": ' 1 year, 5 months' },
    { "name": 'JANSSEN-CILAG Portugal', "desc": '(Pharmaceutical Company)', "time": ' 1 year, 2 months' }
  ]

  export const byMonth = [
    { "name": 'PROFARIN',  "desc": '(Pharmaceutical Wharehouse & Distribution)',  "time": ' 9 months' },
    { "name": 'LEYA, SA',  "desc": '(Book Publishing Company)',  "time": ' 7 months' },
    { "name": 'RIOPELE',  "desc": '(Clothing Manufacturer)',  "time": ' 7 months' },
    { "name": 'TELECEL (now VODAFONE)',  "desc": '(Mobile Operator)',  "time": ' 6 months' },
    { "name": 'WAYFIELD',  "desc": '(Trading International)',  "time": ' 5 months' },
    { "name": 'RTP - Radio Televisão Portuguesa',  "desc": '(Public Service Broadcasting)',  "time": ' 3 months' },
    { "name": 'COMPAL/SUMOL',  "desc": '(Food & Beverage)',  "time": ' 3 months' },
    { "name": 'EXERCITO PORTUGUÊS',  "desc": '(Portuguese Army)',  "time": ' 3 months' },
    { "name": 'MOTA-ENGIL',  "desc": '(Civil Construction)',  "time": ' 2 months' },
    { "name": 'NUTRICAFÉS',  "desc": '(Food & Beverage)',  "time": ' 2 months' },
    { "name": 'RENOVA',  "desc": '(Consumer Brands)',  "time": ' 2 months' },
    { "name": 'AdP - Águas de Portugal',  "desc": '(Water Utility Company)',  "time": ' 2 months' },
    { "name": 'JERÓNIMO MARTINS',  "desc": '(Food Distribution and Specialised Retail)',  "time": ' 2 months' },
    { "name": 'JERONIMO MARTINS POLSKA', "desc": '(Food Distribution and Specialised Retail)', "time": ' 2 months'},
    { "name": 'B.BRAUN Medical',  "desc": '(Hospital/Medical Company)',  "time": ' 2 months' },
    { "name": 'ECM - Cervejas da Madeira',  "desc": '(Producer and Distributor of Beverages)',  "time": ' 1,5 months' }
]

export const byDay = [
  { "remote": "N", "name": 'GIVAUDAN', "desc": '(Manufacturer of Flavors, Fragrances and Active Cosmetic Ingredients)', "time": '17 days' },
  { "remote": "N", "name": 'ROCHE Portugal', "desc": '(Pharmaceutical Company)', "time": '15 days' },
  { "remote": "N", "name": 'ERNST & YOUNG Portugal', "desc": '(Professional Services)', "time": '12 days' },
  { "remote": "Y", "name": 'ANA Airports of Portugal', "desc": '(Airport Management)', "time": '12 days' },
  { "remote": "N", "name": 'JMV José Maria Vieira', "desc": '(Wine & Coffee Industry)', "time": '10 days' },
  { "remote": "Y", "name": 'SOVENA', "desc": '(Agribusiness Holding)', "time": '8 days' },
  { "remote": "N", "name": 'BDF Portugal', "desc": '(Beierdorf - Pharmaceutical Company)', "time": '5 days' },
  { "remote": "N", "name": 'AMBAR', "desc": '(Office Material, etc)', "time": '5 days' },
  { "remote": "Y", "name": 'NEOPLAST', "desc": '(Machining Manufacturer)', "time": '5 days' },
  { "remote": "N", "name": 'ALLIANCE HEALTHCARE', "desc": '(Healthcare Company)', "time": '4 days' },
  { "remote": "N", "name": 'UNICER', "desc": '(Brewery)', "time": '4 days' },
  { "remote": "N", "name": 'PORTUCEL', "desc": '(Pulp and paper industry company)', "time": '4 days' },
  { "remote": "Y", "name": 'ENVC Estaleiros Navais Viana Castelo', "desc": '(Naval Shipyard)', "time": '4 days' },
  { "remote": "N", "name": 'GFI Informatique', "desc": '(IT service management company)', "time": '4 days' },
  { "remote": "N", "name": 'SSR Simons & Simons Rebelo de Sousa', "desc": '(Lawyers)', "time": '4 days' },
  { "remote": "N", "name": 'RPA Rui Pena, Arnaut', "desc": '(Lawyers)', "time": '3 days' },
  { "remote": "N", "name": 'GENERIS', "desc": '(Pharmaceutical Company - Generics)', "time": '3 days' },
  { "remote": "N", "name": 'AIP Associação Indústrial Portuguesa', "desc": '(Portuguese Industrial Association)', "time": '3 days' },
  { "remote": "N", "name": 'ANLORBEL', "desc": '(Building Materials Store)', "time": '1 day' },
  { "remote": "Y", "name": 'COLQUÍMICA', "desc": '(Colquímica Adhesives)', "time": '1 day' },
]