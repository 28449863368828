import React from 'react';
import skillsInfo from '../files/Skills_en.json';

let temp = []
let aux = []

const skills = [];

skillsInfo.map(function (item, i) {
  if (i < 2 || i > 9) {
    skills.push(item);
  }
  return null;
});


const Block = ({temp}) => {
  return (
        temp.map((item) => {
          if (item.url) {
            return (
            <div  key={item.subId}>
                <div className="col-md-4">
                    <a href={item.url} title={item.title} alt={item.title} target="_blank" rel="noopener noreferrer">
                      <img src={item.picture} className="img-responsive img-centered" alt={item.title} />
                    </a>
                    <h4 className="skills-heading">{item.title}</h4>
                    <p className="text-muted"></p>
                </div>
              </div>
            )
          } else {
            return (
              <div  key={item.subId}>
                  <div className="col-md-4">
                     <img src={item.picture} className="img-responsive img-centered" alt={item.title} />
                      <h4 className="skills-heading">{item.title}</h4>
                      <p className="text-muted"></p>
                  </div>
                </div>
              )
          }
        })
  )
}

const Skills_WEB = () => {
    let num = 0
    let max = skills.length - 1
    return (
      skills.map((item, index) => {
          num = num + 1
          if (num === 3 || index === max ) {
            aux = aux.concat(item)
            temp = aux
            aux = []
            num = 0
            return (
              <div key={"SK"+index}>
                <div className="row text-center">
                  <Block temp={temp}/>
                </div>
                <hr />
              </div>
              )
          } else {
            aux = aux.concat(item)
            return null
          }
      })
    )
}

export default Skills_WEB
