import React from "react";

const AboutList = () => {
  return (
    <div className="about-item">
      <strong>
        <div>
          <i className="fa fa-check-square-o"></i> Experience since 1982 in
          computer science and programming
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> Experience since 1993 in
          consulting, implementation, training, support and presentation of the
          SAP ERP system.
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> SAP Standard, SAP/IS-OIL and
          SAP/RETAIL implementations.
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> Development specialist in FI,
          CO, AM, SD, MM, PM, PS, RE-FX
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> Advanced experience in HCM,
          QM and PP developments
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> Proven competence in the
          following technologies/features:
        </div>
      </strong>
      <ul>
        <li>ABAP, ABAP OO, ABAP Query, Web Dynpros, Web Services, Workflow,</li>
        <li>Sapscript, Smartforms, Adobe Forms, ALE/Idocs, RFCs,</li>
        <li>BAPIs, BADIS, BTEs, Enhancements, User-Exits, LSMW e XSLT</li>
      </ul>
      <strong>
        <div>
          <i className="fa fa-check-square-o"></i> SAP HANA (High-Performance
          ANalytic Appliance)
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> SAP HANA XS (Extended
          Application Services)
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> SAP S/4 HANA
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> SAP CLOUD Application
          Programming Model (CAP)
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> ABAP RESTful Application
          Programming Model (ABAP RAP)
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> SAPUI5 (SAP User Interface
          for HTML5)
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> SAP MDG (Master Data
          Governance)
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> SAP Intelligent Robotic
          Process Automation (iRPA)
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> CHATBOTS with SAP
          Conversational AI (CAI)
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> Portal Sites on SAP Cloud
          Platform
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> SAP BTP - Business Technology Platform
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> SAP SPARTACUS (a lean,
          Angular-based JavaScript storefront for SAP Commerce Cloud)
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> Strong defenders of Design
          Thinking, Design Research, Software Design, Copywriting and Sketching
          for IT Business.
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> Basic formation in Internet
          of Things (IoT), Machine Learning and Data Science
        </div>
        <div>
          <i className="fa fa-check-square-o"></i> Experience in Web
          developments/implementations:
        </div>
      </strong>
      <ul>
        <li>
          HTML5, CSS3, Javascript, JSX, JSON, JQuery, AJAX, XML, Node.JS,
          Express.JS, RxJs,
        </li>
        <li>
          TypeScript, Bootstrap, React, React Redux, Angular, Outsystems,
          Spartacus, Webpack,
        </li>
        <li>
          Git, Qunit, Sinon, Databases (MongoDB, MySql, SQLite), Python, VBA,
          etc.
        </li>
        <li>
          Progressive Web Apps, Universal Web Pages, Single Page Applications
        </li>
      </ul>
      <strong>
        <div>
          <i className="fa fa-check-square-o"></i> Skills with openSAP Record of
          Achievement:
        </div>
      </strong>
      <ul>
        <li>Software Development on SAP HANA</li>
        <li>Writing Testable Code for ABAP</li>
        <li>Developing Web Apps with SAPUI5</li>
        <li>
          Building Applications with SAP Cloud Application Programming Model
          (CAP)
        </li>
        <li>
          Building Apps with ABAP RESTFul Application Programming Model (RAP)
        </li>
        <li>
          Building Applications on SAP BTP with Microsoft Services
        </li>
        <li>Building Portal Sites on SAP Cloud Platform</li>
        <li>Build Bots with SAP Intelligent Robotic Process Automation</li>
        <li>
          Application Integration Made Simple with SAP HANA Cloud Integration*
        </li>
        <li>Developing Java-Based Apps on SAP HANA Cloud Platform</li>
        <li>SAP Fiori for iOS - An Introduction</li>
        <li>Imagine IoT / Imagine IoT with SAP Leonardo</li>
        <li>Enterprise Deep Learning with TensorFlow</li>
        <li>Copywriting: Improve User Experience One Word at a Time</li>
        <li>Routing in "Spartacus"</li>
      </ul>
    </div>
  );
};

export default AboutList;
