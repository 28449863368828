import React, { useEffect } from "react";
import sectionsInfo from "../files/Sections_en.json";
import GoTop from "../components/GoTop";

var year = new Date().getFullYear();

const Timeline = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="timeline">
        <div className="only">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading">Timeline</h2>
                <h3 className="section-subheading text-muted">
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 text-center">
                {sectionsInfo.map(function (item, i) {
                  if (item.type === "timeline" && item.subId === "") {
                    return [
                      <h2 key={i} className="section-heading">
                        {item.title}
                      </h2>,
                      <h3 key={i + 1} className="section-subheading text-muted">
                        {item.text}
                      </h3>,
                    ];
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <ul className="timeline">
                  <li>
                    <div className="timeline-image">
                      <h4>
                        <br />
                        {year}
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Resolution, Lda</h4>
                        <h4 className="subheading">We still Alive!</h4>
                        <img
                          src="img/up.png"
                          className="img-responsive img-thumbnail"
                          alt="Ok"
                          width="40"
                        />
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          Sap ABAP Development.
                          <br />
                          SAPUI5 Development.
                          <br />
                          Web Development.
                          <br />
                          Specialist Consultant / Software Architect.
                          <br />
                          ABAP Wizard.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted">
                    <div className="timeline-image">
                      <h4>
                        <br />
                        2024
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Resolution, Lda</h4>
                        <h4 className="subheading">Project with INETUM Spain!</h4>
                        <img
                          src="img/inetum.png"
                          className="img-responsive img-thumbnail"
                          alt="Resolution"
                          width="100"
                        />
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          On August 1st, collaboration with INETUM Spain begins!
                          <br />
                          <br />
                          SAP ABAP Development.
                          <br />
                          Senior Consultant.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="timeline-image">
                      <h4>
                        <br />
                        2021
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Resolution, Lda</h4>
                        <h4 className="subheading">Something has changed!</h4>
                        <img
                          src="img/inetum.png"
                          className="img-responsive img-thumbnail"
                          alt="Resolution"
                          width="100"
                        />
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          On October 1st ROFF became INETUM... and the
                          partnership continues!
                          <br />
                          <br />
                          SAP ABAP Development.
                          <br />
                          Senior Consultant.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted">
                    <div className="timeline-image">
                      <h4>
                        <br />
                        2019
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Resolution, Lda</h4>
                        <h4 className="subheading">20 Years!</h4>
                        <img
                          src="img/20anos.jpg"
                          className="img-responsive img-thumbnail"
                          alt="20 anos"
                          width="80"
                        />
                      </div>
                      <div className="timeline-body"></div>
                    </div>
                  </li>

                  <li>
                    <div className="timeline-image">
                      <h4>
                        <br />
                        2017
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Resolution, Lda</h4>
                        <h4 className="subheading">18 Years... whoa!</h4>
                      </div>
                      <div className="timeline-body">
                        <img
                          src="img/18anos.jpg"
                          className="img-responsive img-thumbnail"
                          alt="18 anos"
                          width="80"
                        />
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted">
                    <div className="timeline-image">
                      <h4>
                        <br />
                        2015
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Resolution, Lda</h4>
                        <h4 className="subheading">
                          and now... Web Development!
                        </h4>
                        <img
                          src="img/web.png"
                          className="img-responsive img-thumbnail"
                          alt="Web"
                          width="40"
                        />
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          Web Development Education
                          <br />
                          (All staff in the Skills section).
                          <br />
                          <br />
                          Web Development.
                          <br />
                          SAP ABAP Development.
                          <br />
                          Senior Consultant.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="timeline-image">
                      <h4>
                        <br />
                        1999
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Resolution, Lda</h4>
                        <h4 className="subheading">We are alive!</h4>
                        <img
                          src="img/Resolution.jpg"
                          className="img-responsive img-thumbnail"
                          alt="Resolution"
                          width="100"
                        />
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          ... and a partnership with ROFF was Born!
                          <br />
                          <br />
                          SAP ABAP Development.
                          <br />
                          Senior Consultant.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted">
                    <div className="timeline-image">
                      <h4>
                        <br />
                        1996
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Luís Rocha</h4>
                        <h4 className="subheading">We start ROFF!</h4>
                        <img
                          src="img/roff.jpg"
                          className="img-responsive img-thumbnail"
                          alt="Roff"
                          width="60"
                        />
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          Founding partner and Consultant at ROFF in the early
                          years.
                          <br />
                          <br />
                          ABAP Development.
                          <br />
                          Senior Consultant.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="timeline-image">
                      <h4>
                        <br />
                        1993
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Luís Rocha</h4>
                        <h4 className="subheading">@ORIGIN Portuguesa, SA</h4>
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          <strong>SAP R/3.</strong>
                          <br />
                          ABAP training in Madrid and Barcelona.
                          <br />
                          ORACLE training in Lisbon.
                          <br />
                          <br />
                          ABAP Development.
                          <br />
                          Senior Consultant.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted">
                    <div className="timeline-image">
                      <h4>
                        <br />
                        1990
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Luís Rocha</h4>
                        <h4 className="subheading">@EDISOFT, SA</h4>
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          System analyst / programmer.
                          <br />
                          COBOL Programming.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li>
                    <div className="timeline-image">
                      <h4>
                        <br />
                        1987
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Luís Rocha</h4>
                        <h4 className="subheading">@PHILIPS Portuguesa, SA</h4>
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          Software Support.
                          <br />
                          Duties in the Department of telecommunications and
                          information technology.
                        </p>
                      </div>
                    </div>
                  </li>

                  <li className="timeline-inverted">
                    <div className="timeline-image">
                      <h4>
                        <br />
                        1982
                      </h4>
                    </div>
                    <div className="timeline-panel">
                      <div className="timeline-heading">
                        <h4>Luís Rocha</h4>
                        <h4 className="subheading">Freelancer</h4>
                      </div>
                      <div className="timeline-body">
                        <p className="text-muted">
                          Programming on ZX Spectrum and Amstrad PCW computers
                          (Basic language).
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <GoTop />
    </div>
  );
};

export default Timeline;
