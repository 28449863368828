import React, { useEffect } from "react";
import AboutIntro from "../components/aboutIntro";
import GoTop from "../components/GoTop";

const About = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <section id="intro">
      <div className="only">
        <AboutIntro />
        <GoTop />
      </div>
    </section>
  );
};

export default About;
