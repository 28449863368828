import React, { useEffect } from "react";
import SkillsIntro from "../components/skills_web";
import SkillsMore from "../components/skills_web+";
import GoTop from "../components/GoTop";
import Start from "../components/Start";
import { Carousel } from "react-responsive-carousel";
import skillsInfo from "../files/Skills_en.json";

const items = [];

skillsInfo.map(function (item, i) {
  if (i > 2) {
    items.push(item);
  }
  return null;
});

const info = [
  "<h5>Progressive Web Applications</h5>",
  "<h5>Universal Web Applications</h5>",
  "<h5>Single Page Applications</h5>",
  "<h5>HTML5</h5>",
  "<h5>CSS3</h5>",
  "<h5>SASS</h5>",
  "<h5>LESS</h5>",
  "<h5>Javascript</h5>",
  "<h5>JQuery</h5>",
  "<h5>JSON</h5>",
  "<h5>JSX</h5>",
  "<h5>XML</h5>",
  "<h5>TypeScript</h5>",
  "<h5>Bootstrap</h5>",
  "<h5>Node.JS</h5>",
  "<h5>Express.JS</h5>",
  "<h5>RxJS</h5>",
  "<h5>React</h5>",
  "<h5>React Redux</h5>",
  "<h5>Backbone</h5>",
  "<h5>Angular</h5>",
  "<h5>WordPress</h5>",
  "<h5>SQL</h5>",
  "<h5>SQLite</h5>",
  "<h5>MongoDB</h5>",
  "<h5>Git / GitHub</h5>",
  "<h5>Ruby</h5>",
  "<h5>Ruby on Rails</h5>",
  "<h5>Python</h5>",
  "<h5>Django</h5>",
  "<h5>ASP.Net MVC</h5>",
  "<h5>EJS</h5>",
  "<h5>Hogan.JS (HJS)</h5>",
  "<h5>PUG (Jade)</h5>",
  "<h5>VBA</h5>",
  "<h5>Webpack</h5>",
  "<h5>GRUNT</h5>",
  "<h5>Qunit</h5>",
  "<h5>Sinon.JS</h5>",
  "<h5>Gulp</h5>",
  "<h7>Babel</h7><br />",
  "<h7>Baboo</h7><br />",
  "<h7>Elixir</h7><br />",
  "<h7>Marionette</h7><br />",
  "<h7>Coffeescript</h7><br />",
  "<h5>SEO</h5>",
  "<h5>Web Design</h5>",
  "<h5>Leadership</h5>",
  "<h5>Training</h5>",
  "<h5>Research</h5>",
  "<h5>Management</h5>",
  "<h5>Sales</h5>",
];

const AllWebSkills = () => {
  return (
    <div className="text-center">
      <h3 className="section-heading text-muted">All Web Skills</h3>
      <br />
      {info.map((value, index) => {
        return (
          <div key={"I" + index}>
            <span
              dangerouslySetInnerHTML={{
                __html: value,
              }}
            ></span>
          </div>
        );
      })}
    </div>
  );
};

const Slider = () => {
  return (
    <div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        // showArrows={false}
        showIndicators={false}
        infiniteLoop
        autoPlay
        emulateTouch
        // width="100%"
        // dynamicHeight={true}
      >
        {items.map((item, index) => {
          return <div key={"S" + index}>{item.title}</div>;
        })}
      </Carousel>
      <br />
    </div>
  );
};

const WebSkills = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  return (
    <section id="skills">
      <div className="only">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="section-heading">WEB Skills</h2>
              <h3 className="section-subheading text-muted">Our Web Skills</h3>
            </div>
          </div>

          <Start path="/WebSkills" />

          <Slider />

          <SkillsIntro />
          <br />
          <SkillsMore />
          <br />
          <AllWebSkills />
          <GoTop />
        </div>
      </div>
    </section>
  );
};

export default WebSkills;
