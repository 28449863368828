import React from "react";

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Start from "./Start";

import Highlights from "./projectsHighlight";
import Important from "./projectsImportant";
import ByMonth from "./projectsByMonth";
import ByDay from "./projectsByDay";

import { current, byYear, byMonth } from "../info/projects";

const items = [];

current.map(function (item, i) {
  items.push(item);
  return null;
});

byYear.map(function (item, i) {
  items.push(item);
  return null;
});

byMonth.map(function (item, i) {
  items.push(item);
  return null;
});

const Slider = () => {
  return (
    <div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        // showArrows={false}
        showIndicators={false}
        infiniteLoop
        autoPlay
        emulateTouch
        // width="100%"
        // dynamicHeight={true}
      >
        {items.map((item, index) => {
          return (
            <div
              key={"S" + index}
            >
              {item.name}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

const projectsIntro = ({ user, handleLike, handleUnLike }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading">Projects</h2>
          <h3 className="section-subheading text-muted">
            Projects with the participation of Resolution
            <br />
            <br />
            (Almost all projects through <strong>ROFF</strong>)
          </h3>
        </div>
      </div>

      <Start path="Projects" />

      <Slider />

      <Highlights />

      <Important />

      <ByMonth />

      <ByDay />
    </div>
  );
};

export default projectsIntro;
