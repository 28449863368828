import React from "react";
import SapSkills from "./skills_sap";
import WebSkills from "./skills_web";
import {Link} from 'react-router-dom';

const LandSkills = () => {
  return (
    <section id="skills">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Skills</h2>
            <br />
            <h3 className="section-subheading text-muted">Our SAP Skills</h3>
          </div>
        </div>

        <br />
        <SapSkills />

        <hr />
        <div className="row text-center">
          <div className="col-md-12">
            <button type="button" className="rci-button">
              <Link to="SapSkills">All SAP Skills</Link>
            </button>
          </div>
        </div>
        <div className="text-center">

      </div>
        <br />
        <br />
        <br />

        <div className="row">
          <div className="col-lg-12 text-center">
            <h3 className="section-subheading text-muted">Our Web Skills</h3>
          </div>
        </div>

        <WebSkills />

        <br />
        <div className="row text-center">
          <div className="col-md-12">
            <button type="button" className="rci-button">
              <Link to="WebSkills">All Web Skills</Link>
            </button>
          </div>
        </div>
        <br />
      </div>
    </section>
  );
};

export default LandSkills;
