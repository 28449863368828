export const courses = [
  {
    name: "SAP ABAP",
    desc: "Show SAP skills",
    href: "http://go.sap.com/index.html",
    title: "SAP",
    image: "img/sap.png",
    badge: "",
  },
  {
    name: "SAPUI5",
    desc: "",
    href: "https://sapui5.hana.ondemand.com/",
    title: "SAPUI5",
    image: "img/sapui5.png",
    badge: "img/Finisher.jpg",
  },
  {
    name: "SAP HANA CLOUD PLATFORM",
    desc: "",
    href: "https://hcp.sap.com/index.html",
    title: "HANA",
    image: "img/SapHana.PNG",
    badge: "",
  },
  {
    name: "SAP CLOUD Application Programming Model",
    desc: "",
    href: "https://cap.cloud.sap/docs/",
    title: "CAP",
    image: "img/cap.svg",
    badge: "img/cp7_open_badge.png",
  },
  {
    name: "ABAP RESTful Application Programming Model",
    desc: "",
    href: "https://help.sap.com/viewer/fc4c71aa50014fd1b43721701471913d/202009.000/en-US/289477a81eec4d4e84c0302fb6835035.html",
    title: "ABAP RAP",
    image: "img/abap-rap.png",
    badge: "img/cp13_open_badge.png",
  },
  {
    name: "SAP Intelligent Robotic Process Automation",
    desc: "",
    href: "https://help.sap.com/viewer/product/IRPA/Cloud/en-US",
    title: "iRPA",
    image: "img/iRPA.jpg",
    badge: "img/rpa2_open_badge.png",
  },
  {
    name: "CHATBOTS with SAP Conversational AI",
    desc: "",
    href: "https://cai.tools.sap/",
    title: "CAI",
    image: "img/chatbot.jpg",
    badge: "img/cai1_open_badge.png",
  },
  {
    name: "SAP Business Technology Platform",
    desc: "",
    href: "https://account.hana.ondemand.com/#/home/welcome",
    title: "BTP",
    image: "img/btp.png",
    badge: "img/btpma1_open_badge.png",
  },
  {
    name: "SPARTACUS",
    desc: "Angular-based JavaScript storefront for SAP Commerce Cloud",
    href: "https://sap.github.io/spartacus-docs/",
    title: "SPARTACUS",
    image: "img/spartacus.jpg",
    badge: "img/sparta1_open_badge.png",
  }
]
