import React from "react";
import GamesIntro from "./gamesIntro";

const LandGames = () => {
  return (
    <section id="sites">
      <GamesIntro />
    </section>
  );
};

export default LandGames;
