import React from "react";

const Hero = () => {
  return (
    <header>
      <section id="header">
        <div className="container">
          <img
            src="/resolution.png"
            className="img-responsive img-centered"
            alt="resolution, lda"
          />
        </div>
      </section>
    </header>
  );
};

export default Hero;
