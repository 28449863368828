import React from "react";
import SitesIntro from "./sitesIntro";

const LandSites = () => {
  return (
    <section id="sites">
      <SitesIntro />
    </section>
  );
};

export default LandSites;
