import React from "react";
import Texts from "./aboutList";

const LandIntro = () => {
  return (
    <section id="intro" className="bg-light-gray">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Introduction</h2>
            <h3 className="section-subheading text-muted">
              Experience in Software Development since 1982
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <Texts />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandIntro;
