import React from "react";
import { courses } from "../info/opensap";

const Badge = ({ num }) => {
  return (
    <>
      <img
        src={courses[num].badge}
        className="img-responsive img-centered"
        alt={courses[num].title}
        width="30"
        // eslint-disable-next-line no-sequences
        onMouseOver={(e) => (
          // eslint-disable-next-line no-sequences
          (e.currentTarget.src = courses[num].badge),
          (e.currentTarget.width = "200")
        )}
        // eslint-disable-next-line no-sequences
        onMouseOut={(e) => (
          // eslint-disable-next-line no-sequences
          (e.currentTarget.src = courses[num].badge),
          (e.currentTarget.width = "30")
        )}
      />
      <i className="bi bi-hand-index hidden-md hidden-lg"></i>
    </>
  );
};

const Badge1 = ({ num }) => {
  return (
    <>
      <img
        src={courses[num].badge}
        className="img-responsive img-centered"
        alt={courses[num].title}
        width="50"
        // eslint-disable-next-line no-sequences
        onMouseOver={(e) => (
          // eslint-disable-next-line no-sequences
          (e.currentTarget.src = courses[num].badge),
          (e.currentTarget.width = "200")
        )}
        // eslint-disable-next-line no-sequences
        onMouseOut={(e) => (
          // eslint-disable-next-line no-sequences
          (e.currentTarget.src = courses[num].badge),
          (e.currentTarget.width = "50")
        )}
      />
      <i className="bi bi-hand-index hidden-md hidden-lg"></i>
    </>
  );
};

const Skills_SAP = () => {
  return (
    <div>
      <div className="row text-center">
        <div className="col-md-4">
          <a
            href={courses[0].href}
            title={"go to " + courses[0].title}
            alt={courses[0].title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={courses[0].image}
              className="img-responsive img-centered"
              alt={courses[0].title}
            />
          </a>
          <h4 className="skills-heading">{courses[0].name}</h4>
          <p className="text-muted"></p>
          {/* <a href="#sapModal" className="btn btn-info" data-toggle="modal" role="button">{courses[0].desc}</a> */}
          <br />
          <br />
        </div>
        <div className="col-md-4">
          <a
            href={courses[1].href}
            title={"go to " + courses[1].title}
            alt={courses[1].title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={courses[1].image}
              className="img-responsive img-centered"
              alt={courses[1].title}
              width="120"
            />
          </a>
          <h4 className="skills-heading">{courses[1].name}</h4>
          <Badge1 num="1" />
          <p className="text-muted"></p>
        </div>
        <div className="col-md-4">
          <br />
          <a
            href={courses[2].href}
            title={"go to " + courses[2].title}
            alt={courses[2].title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={courses[2].image}
              className="img-responsive img-centered"
              alt={courses[2].title}
            />
          </a>
          <h4 className="skills-heading">{courses[2].name}</h4>
          <p className="text-muted"></p>
        </div>
      </div>
      <br />
      <div className="row text-center">
        <div className="col-md-6">
          <a
            href={courses[3].href}
            title={"go to " + courses[3].title}
            alt={courses[3].title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={courses[3].image}
              className="img-responsive img-centered"
              alt={courses[3].title}
              width="120"
            />
          </a>
          <h4 className="skills-heading">
            {courses[3].name}
            <br />
            {"(" + courses[3].title + ")"}
          </h4>
          <Badge num="3" />
          <p className="text-muted"></p>
        </div>
        <div className="col-md-6">
          <a
            href={courses[4].href}
            title={"go to " + courses[4].title}
            alt={courses[4].title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={courses[4].image}
              className="img-responsive img-centered"
              alt={courses[4].title}
              width="185"
            />
          </a>
          <h4 className="skills-heading">
            {courses[4].name}
            <br />
            {"(" + courses[4].title + ")"}
          </h4>
          <Badge num="4" />
          <p className="text-muted"></p>
        </div>
      </div>
      <br />
      <div className="row text-center">
        <div className="col-md-6">
          <br />
          <a
            href={courses[5].href}
            title={"go to " + courses[5].title}
            alt={courses[5].title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={courses[5].image}
              className="img-responsive img-centered"
              alt={courses[5].title}
              width="210"
            />
          </a>
          <h4 className="skills-heading">
            {courses[5].name}
            <br />
            {"(" + courses[5].title + ")"}
          </h4>
          <Badge num="5" />
          <p className="text-muted"></p>
        </div>
        <div className="col-md-6">
          <a
            href={courses[6].href}
            title={"go to " + courses[6].title}
            alt={courses[6].title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={courses[6].image}
              className="img-responsive img-centered"
              alt={courses[6].title}
              width="120"
            />
          </a>
          <h4 className="skills-heading">
            {courses[6].name} <br />
            {"(" + courses[6].title + ")"}
          </h4>
          <Badge num="6" />
          <p className="text-muted"></p>
        </div>
      </div>
      <br />
      <div className="row text-center">
        <div className="col-md-6">
          <a
            href={courses[7].href}
            title={"go to SAP " + courses[7].title}
            alt={"SAP " + courses[7].title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={courses[7].image}
              className="img-responsive img-centered"
              alt={"SAP " + courses[7].title}
              width="250"
            />
          </a>
          <h4 className="skills-heading">
            {courses[7].name}
            <br />
            {courses[7].desc}
          </h4>
          <Badge num="7" />
          <p className="text-muted"></p>
        </div>
        <div className="col-md-6">
          <a
            href={courses[8].href}
            title={"go to SAP " + courses[8].title}
            alt={"SAP " + courses[8].title}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={courses[8].image}
              className="img-responsive img-centered"
              alt={"SAP " + courses[8].title}
              width="250"
            />
          </a>
          <h4 className="skills-heading">
            {courses[8].name}
            <br />
            {courses[8].desc}
          </h4>
          <Badge num="8" />
          <p className="text-muted"></p>
        </div>
      </div>
    </div>
  );
};

export default Skills_SAP;
