import React from "react";
import { Link } from "react-router-dom";

var year = new Date().getFullYear();

const Footer = () => {
  return (
    <div>
      <footer className="text-center" id="page-bottom">
        <div className="footer-above">
          <div className="container-fluid">
            <div className="row">
              <div className="footer-col hidden-lg col-xs-6 col-sm-6 col-md-6 text-center">
                <ul>
                  <li>
                    <Link to="/" alt="Resolution - Home">
                      Home
                    </Link>
                  </li>

                  <li>
                    <Link to="About" alt="Resolution - About">
                      About
                    </Link>
                  </li>

                  <li>
                    <Link to="SapSkills" alt="Resolution - Skills">
                      SAP Skills
                    </Link>
                  </li>
                  <li>
                    <Link to="WebSkills" alt="Resolution - Skills">
                      Web Skills
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-col visible-lg col-lg-2 text-left">
                <ul>
                  <li>
                    <Link to="/" alt="Resolution - Home">
                      Home
                    </Link>
                  </li>

                  <li>
                    <Link to="About" alt="Resolution - About">
                      About
                    </Link>
                  </li>

                  <li>
                    <Link to="SapSkills" alt="Resolution - SAP Skills">
                      SAP Skills
                    </Link>
                  </li>
                  <li>
                    <Link to="WebSkills" alt="Resolution - Web Skills">
                      Web Skills
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-col hidden-lg col-xs-6 col-sm-6 col-md-6 text-center">
                <ul>
                  <li>
                    <Link to="Projects" alt="Resolution - Projects">
                      Projects
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="ProjectByTime"
                      alt="Resolution - Projects by Time"
                    >
                      Projects by Time
                    </Link>
                  </li>

                  <li>
                    <Link to="Sites" alt="Resolution - Sites">
                      Sites
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="Games" alt="Resolution - Games">
                      Games
                    </Link>
                  </li>

                  <li>
                    <Link to="Timeline" alt="Resolution - Timeline">
                      Timeline
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-col visible-lg col-lg-2 text-left">
                <ul>
                  <li>
                    <Link to="Projects" alt="Resolution - Projects">
                      Projects
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="ProjectsByTime"
                      alt="Resolution - Projects by Time"
                    >
                      Projects by Time
                    </Link>
                  </li>

                  <li>
                    <Link to="Sites" alt="Resolution - Sites">
                      Sites
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Link to="Games" alt="Resolution - Games">
                      Games
                    </Link>
                  </li>

                  <li>
                    <Link to="Timeline" alt="Resolution - Timeline">
                      Timeline
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-col col-xs-12 col-sm-12 col-md-6 col-lg-4">
                <ul className="list-inline">
                  <li>
                    <p className="btn-social btn-outline">
                      <i className="fa fa-fw fa-facebook"></i>
                    </p>
                  </li>
                  <li>
                    <p className="btn-social btn-outline">
                      <i className="fa fa-fw fa-google-plus"></i>
                    </p>
                  </li>
                  <li>
                    <p className="btn-social btn-outline">
                      <i className="fa fa-fw fa-twitter"></i>
                    </p>
                  </li>
                  <li>
                    <a
                      href="https://pt.aedin.com/in/luís-rocha-324688105"
                      className="btn-social btn-outline"
                    >
                      <i className="fa fa-fw fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="footer-col col-xs-12 col-sm-12 col-md-6 col-lg-4 text-center">
                <p>
                  <font size="4">Powered by:</font>
                </p>
                <i>Resolution - Consultores Informáticos, Lda.</i>
                <br /><br />
                <p>
                  <Link to="Policy" alt="Resolution - Privacy and Cookies Policy">
                  Privacy and Cookies Policy
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-below">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                All content
                <i className="glyphicon glyphicon-copyright-mark"></i>
                {year} Resolution.pt All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
