import React, { useEffect } from "react";
// import sectionsInfo from "../files/Sections_en.json";
// import GoTop from "../components/GoTop";

const Policy = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);
  return (
    <div>
      <section id="timeline">
        <div className="only">
          <div className="container">
            {/* <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="section-heading">Policy</h2>
                <h3 className="section-subheading text-muted">***</h3>
              </div>
            </div> */}
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2>Privacy Policy / Politica de Privacidade</h2>
                <h4 className="section-subheading text-left">
                  Resolution Lda does not collect personal data or information
                  of any other nature. As such, it does not process or disclose
                  data and information in any way.
                  </h4>
                  <h4 className="section-subheading text-muted text-left">
                  A Resolution Lda não recolhe dados pessoais ou
                  informação de qualquer outra natureza. Como tal, não processa
                  ou divulga dados e informações de forma alguma.
                </h4>
                <h2>Cookies Policy / Politica de Cookies</h2>
                <h4 className="section-subheading text-left">
                  Resolution Lda does not use cookies of any type on its
                  website.
                  </h4>
                  <h4 className="section-subheading text-muted text-left">
                  A Resolution Lda não usa cookies de qualquer tipo no seu
                  site.
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Policy;
