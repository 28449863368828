import React, {useEffect} from 'react';
import SitesIntro from "../components/sitesIntro";
import GoTop from "../components/GoTop";

const Sites = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }, []);
  return (
    <section id="sites">
      <div className="only">
        <SitesIntro />
        <GoTop />
      </div>
    </section>
  );
};

export default Sites;
