import React from "react";
import {Link} from 'react-router-dom';

import Highlights from "./projectsHighlight";

const LandProjects = () => {
  return (
    <section id="projects" className="bg-light-gray">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <h2 className="section-heading">Projects</h2>
            <h3 className="section-subheading text-muted">
              Projects with the participation of Resolution
              <br />
              <br />
              (Almost all projects through <strong>ROFF</strong>)
            </h3>
          </div>
        </div>

        <Highlights />

        <div className="hidden-md hidden-lg">
          <br />
        </div>
        <div className="row text-center">
          <div className="col-md-6">
            <button type="button" className="rci-button">
              <Link to="Projects">All Resolution Projects</Link>
            </button>
          </div>
          <div className="hidden-md hidden-lg">
            <br />
          </div>
          <div className="col-md-6">
            <button type="button" className="rci-button">
              <Link to="ProjectsByTime">Show Projects by Time</Link>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandProjects;
