import React, {useEffect} from 'react';
import GamesIntro from "../components/gamesIntro";
import GoTop from "../components/GoTop";

const Games = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
  }, []);
  return (
    <section id="sites">
      <div className="only">
        <GamesIntro />
        <GoTop />
      </div>
    </section>
  );
};

export default Games;
