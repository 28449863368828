import * as React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import "./styles/hamburger.scss";
import "./styles/carousel.css"; // change this to the file path of your overrides
import "./styles/imports.scss";
import "./styles/bootstrap.min.css";
import "./styles/rci.css";

// import Header from "./components/Header";
// import Footer from "./components/Footer";
import Sites from "./pages/Sites";
import Games from "./pages/Games";
import WebSkills from "./pages/WebSkills";
import SapSkills from "./pages/SapSkills";
import Projects from "./pages/Projects";
import Timeline from "./pages/Timeline";
import About from "./pages/About";
import ProjectsByTime from "./pages/ProjectsByTime";
import Landing from "./pages/Landing";
import Layout from "./components/Layout";
import Policy from "./pages/Policy";

const App = () => {
  return (
    <>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Landing />} />
          <Route path="About" element={<About />} />
          <Route path="WebSkills" element={<WebSkills />} />
          <Route path="SapSkills" element={<SapSkills />} />
          <Route path="Projects" element={<Projects />} />
          <Route path="Sites" element={<Sites />} />
          <Route path="Games" element={<Games />} />
          <Route path="Timeline" element={<Timeline />} />
          <Route path="ProjectsByTime" element={<ProjectsByTime />} />
          <Route path="Policy" element={<Policy />} />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Route>
      </Routes>
      {/* <Footer /> */}
    </>
  );
};

export default App;
