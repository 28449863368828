import React from 'react';
import { important } from '../info/projects'

let temp = []
let aux = []

const Block = ({temp}) => {
  return (
        temp.map((item, index) => {
            return (
            <div key={"PI"+index}>
              <div className="col-md-4 col-sm-6 projects-item">
              <h4>{item.name}</h4>
              <p className="text-muted">{item.desc}</p>
              </div>
            </div>
            )
        })
  )
}

const ProjectsImpo = () => {
  let num = 0
  let max = important.length - 1
  return (
    important.map((item, index) => {
        num = num + 1
        if (num === 3 || index === max ) {
          aux = aux.concat(item)
          temp = aux
          aux = []
          num = 0
          return (
            <div key={"SK"+index}>
              <div className="row text-center">
                <Block temp={temp}/>
              </div>
            </div>
            )
        } else {
          aux = aux.concat(item)
          return null
        }
    })
  )
}

const ProjectsImportant= () => {
  return (
    <div>
     <ProjectsImpo />
   </div>
  )
}

export default ProjectsImportant


