import React from 'react';
import { highlights, current } from '../info/projects'

let temp = []
let aux = []

const Block = ({temp}) => {
  return (
        temp.map((item, index) => {
            return (
            <div key={"PH"+index}>
              <div className="col-md-4 col-sm-6 projects-item">
              <h4>{item.name}</h4>
              <p className="text-muted">{item.desc}</p>
              </div>
            </div>
            )
        })
  )
}

const CurrentProject = () => {
  return current.map((item, index) => {
    return (
      <div key={"CP" + index}>
        <div>
          <h4>{item.name}</h4>
          <p className="text-muted">{item.desc}<br />{item.time}</p>
        </div>
      </div>
    );
  });
}

const ProjectsHigh = () => {
  let num = 0
  let max = highlights.length - 1
  return (
    highlights.map((item, index) => {
        num = num + 1
        if (num === 3 || index === max ) {
          aux = aux.concat(item)
          temp = aux
          aux = []
          num = 0
          return (
            <div key={"SK"+index}>
              <div className="row text-center">
                <Block temp={temp}/>
              </div>
            </div>
            )
        } else {
          aux = aux.concat(item)
          return null
        }
    })
  )
}

const ProjectsHighlight = () => {
  return (
    <div>
      <div className="row text-center">

        <div className="col-md-3 col-sm-6 projects-item">
          <h5>--- now ---</h5>
          <p className="text-muted"></p>
        </div>

        <div className="col-md-6 col-sm-6 projects-item">
           <CurrentProject />
        </div>

        <div className="col-md-3 col-sm-6 projects-item">
          <h5>--- now ---</h5>
          <p className="text-muted"></p>
        </div>

      </div>

     <ProjectsHigh />
   </div>
  )
}

export default ProjectsHighlight


